import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Star = (
  <svg
    className="w-4 h-4"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="#F68B1F"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

function RecentProjectTile({ project }) {
  let { frontmatter } = project.node;
  let { title, description, slug, city, zip} = frontmatter; 

     
  let showImages = false
  let showMaxImageCount = 0
  let projectImages = []


  //check if project images exist, and if more than 4, slice array to only for 
  //since that is max we show for project tile preview
  if(project.node.frontmatter.images) {
    showImages = true
    showMaxImageCount = project.node.frontmatter.images.length
    if(showMaxImageCount > 3) {
      showMaxImageCount =4
    }
    projectImages = project.node.frontmatter.images.slice(0,showMaxImageCount)
  }

 
  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="flex flex-wrap p-3">
        <div className="md:pr-12 w-full md:w-1/2 grid grid-cols-2">
          {showImages && projectImages.map((image, i) => (
            <div
              className={`items-center flex justify-center rounded-xl overflow-hidden m-2 ${
                i > 1 ? "hidden md:flex" : ""
              }`}
            >
              <Img
                fixed={image.image.childImageSharp.fixed}
                className="w-full h-full"
              />
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/2 p-2">
          <div
            style={{ backgroundColor: "#EBF5F0" }}
            className=" font-semibold uppercase text-green-700 inline-block px-3 rounded-full py-2 text-xs md:text-sm mb-2"
          >
            SIDING
          </div>
          <h2 className="font-semibold mb-1 text-xl md:text-2xl pr-8">{title} in {city}, {zip}</h2>
          <span className="font-normal text-lg md:text-xl block text-gray-500 font-display">
            {description}
          </span>
          <div className="border-t border-b border-gray-200 my-4 py-6">
            <div className="flex items-top">
              <svg
                width="30"
                height="30"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
                  fill="#AAB7C4"
                  fill-opacity="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.4999 27C11.6671 27.0007 9.85357 26.6268 8.1705 25.9013C6.48743 25.1759 4.97036 24.1141 3.7124 22.7812C5.14745 20.1487 8.1755 18.3775 13.4999 18.3775C18.8243 18.3775 21.8537 20.1487 23.2874 22.7812C22.0294 24.1141 20.5123 25.1758 18.8293 25.9012C17.1462 26.6267 15.3327 27.0006 13.4999 27ZM13.5053 5.08815L13.5067 5.08545C16.3106 5.08545 18.5813 7.1199 18.5813 10.4274C18.5813 13.7349 16.3093 15.7761 13.5053 15.7761C10.7027 15.7761 8.4293 13.7403 8.4293 10.4328C8.4293 7.1253 10.7041 5.0868 13.5053 5.0868V5.08815Z"
                  fill="#AAB7C4"
                />
              </svg>
              <div className="ml-2">
                <span className="block font-semibold text-black font-display">
                  Ken G.
                </span>
                <div className="flex items-center">
                  {Star}
                  {Star}
                  {Star}
                  {Star}
                  {Star}
                </div>
              </div>
            </div>
            <span className="text-sm font-display text-gray-500 mt-1 block">
              Bay Area Siding was easy to work with and did a great job on our
              siding and rain gutters. They worked with us to make our house
              look like new.
            </span>
          </div>
          <div className="text-right">
            <Link
              to={`/project/${slug}`}
              title={"Explore Projcet"}
              className="font-medium font-display inline-flex items-center button-active-bhvr"
            >
              Explore Project{" "}
              <svg
                className="text-gray-400 w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentProjectTile;
